import { onAuthStateChanged, signOut } from "firebase/auth";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebase";
import Show from "./Show";

function Navbar() {
  const [user, setUser] = useState(undefined);

  const navigate = useNavigate();
  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) setUser(currentUser);
    else navigate("/login");
  });

  return (
    <div className="flex">
      <aside className="w-60 inset-y-0 left-0 bg-blue-900 shadow-md">
        <div className="flex flex-col justify-between h-full">
          <div className="flex-grow">
            <div className="px-4 pt-4 pb-3  text-center border-b">
              <h1 className="text-xl font-bold leading-none">
                <span className="text-amber-400">UPeU </span> 
                <span className="text-slate-50">Ganaderos </span> 

                <p className="pt-2 font-normal text-xs text-slate-50">{user?.email}</p>
              </h1>
            </div>
            <div className="p-4">
              <ul className="space-y-1">
                <li>
                  <Link
                    to="/"
                    className="flex items-center bg-gradient-to-r from-green-600 to-green-400 rounded-lg font-bold text-white text-xs py-3 px-4 shadow-md shadow-green-500/20 hover:shadow-lg hover:shadow-green-500/40 active:opacity-[0.85] w-full gap-4 capitalize"
                    style={{ overflow: "hidden", position: "relative" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                      className="w-6 h-6"
                    >
                      <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z"></path>
                      <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z"></path>
                    </svg>
                    <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">
                      Inicio
                    </p>
                  </Link>
                </li>


                <li>
                  <Link
                    to="/asistencia"
                    className="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-gray-500 hover:bg-cyan-200 active:bg-cyan-500/30 w-full flex items-center gap-4 px-4 capitalize"
                  >
                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                    <path d="M12.687 14.408a3.01 3.01 0 0 1-1.533.821l-3.566.713a3 3 0 0 1-3.53-3.53l.713-3.566a3.01 3.01 0 0 1 .821-1.533L10.905 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V11.1l-3.313 3.308Zm5.53-9.065.546-.546a2.518 2.518 0 0 0 0-3.56 2.576 2.576 0 0 0-3.559 0l-.547.547 3.56 3.56Z"/>
                    <path d="M13.243 3.2 7.359 9.081a.5.5 0 0 0-.136.256L6.51 12.9a.5.5 0 0 0 .59.59l3.566-.713a.5.5 0 0 0 .255-.136L16.8 6.757 13.243 3.2Z"/>
                    </svg>

                    <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">
                    <span className="text-slate-50">Asistencia </span> 

                      
                    </p>
                  </Link>
                </li>      


               
              </ul>
            </div>
          </div>
          
          <div class="text-center">
                  <p className="block mt-4 antialiased font-sans text-sm leading-normal text-gray-900 font-black uppercase opacity-75">
                    
                    <span className="text-slate-50">Página de acceso</span> 

                  </p>
                
                  <button
                    to="/"
                    className="middle none font-sans font-bold center transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 rounded-lg text-gray-500 hover:bg-cyan-200 active:bg-cyan-500/30 w-full flex items-center gap-4 px-4 capitalize"
                    onClick={() => signOut(auth)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm10.72 4.72a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H9a.75.75 0 010-1.5h10.94l-1.72-1.72a.75.75 0 010-1.06z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <p className="block antialiased font-sans text-base leading-relaxed text-inherit font-medium capitalize">
                      <span className="text-slate-50">Cerrar Sesión</span> 

                    </p>
                  </button>
                
                
    <p class="text-xs text-slate-100 text-center mt-8">&copy;
    <span className="text-slate-50"> 2024 Goduz-tec - Versión 1.0</span> 

 </p>
      </div>
        </div>
      </aside>
      <main className="p-5 bg-neutral-50 flex-1 h-screen overflow-y-scroll">
        <Show />
      </main>
    </div>
  );
}

export default Navbar;
