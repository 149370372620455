import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../firebase/firebase";


//primero
const Create = () => {
  // * I. DATOS GENERALES DEL PRODUCTOR
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [dni, setDni] = useState("");
  const [procedencia, setProcedencia] = useState("");
  const [edad, setEdad] = useState("0");
  const [genero, setGenero] = useState("");
  const [ncelular, setNcelular] = useState("");
  const [modalidad, setModalidad] = useState("");
  const [nvaucher, setNvaucher] = useState("");
  const [ntalonario, setNtalonario] = useState("");




  const navigate = useNavigate();

  const productoreCollection = collection(db, "ganaderos24");

  const store = async (event) => {
    event.preventDefault();

    const edadNumber = parseInt(edad, 10);

  
    const productor = {
      //* 1: I. DATOS GENERALES DEL PRODUCTOR
      nombre: nombre,
      apellido: apellido,
      dni: dni,
      procedencia: procedencia,
      modalidad: modalidad,
      genero: genero,
      edad: edadNumber,
      ncelular: ncelular,
      ntalonario: ntalonario,
      nvaucher: nvaucher,
      

      // timestamp: Date,
    };

    await addDoc(productoreCollection, productor);
    navigate("/");
  };

 

  return (
    <div className="py-4 bg-orange-100 ">
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-800 border-0">
      <h1 className="font-bold text-2xl items-baseline font-mono">
          Datos Personales
        </h1>
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              
            </h6>
            <Link
              to="/"
              className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Regresar
            </Link>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form onSubmit={store}>
            {/* //? I. DATOS GENERALES DEL PRODUCTOR  */}
            <div className="flex flex-wrap border mb-2 border-sky-500 p-3 rounded">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    nombre
                  </label>
                  <input
                    value={nombre}
                    onChange={(e) => {
                      setNombre(e.target.value);
                    }}
                    placeholder="Introduce Nombre"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Apellido
                  </label>
                  <input
                    value={apellido}
                    onChange={(e) => {
                      setApellido(e.target.value);
                    }}
                    placeholder="Introduce apellido"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    DNI
                  </label>
                  <input
                    value={dni}
                    onChange={(event) => {
                      setDni(event.target.value);
                    }}
                    placeholder="Introduce dni"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Procedencia
                  </label>
                  <input
                    value={procedencia}
                    onChange={(e) => {
                      setProcedencia(e.target.value);
                    }}
                    placeholder="Introduce Procedencia ejemplo : Juliaca - Puno"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              

             

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Categoria
                  </label>
                  <select
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={modalidad}
                    onChange={(e) => setModalidad(e.target.value)}
                  >
                    <option value="">Seleccione una opción</option>
                    <option value="Profesional">Profesional</option>
                    <option value="Publico General">Publico General</option>
                    <option value="Estudiante">Estudiante</option>
                    <option value="Ganadero">Ganadero</option>
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Genero
                  </label>
                  <select
                    value={genero}
                    onChange={(e) => {
                      setGenero(e.target.value);
                    }}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  >
                    <option value="">Seleccione una opción</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                  </select>
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Edad
                  </label>
                  <input
                    value={edad}
                    onChange={(event) => {
                      setEdad(event.target.value);
                    }}
                    placeholder="Introdusca edad"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Numero Celular
                  </label>
                  <input
                    value={ncelular}
                    onChange={(event) => {
                      setNcelular(event.target.value);
                    }}
                    placeholder="Introdusca numero celular"
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Numero del corredor
                  </label>
                  <input
                    value={ntalonario}
                    onChange={(event) => {
                      setNtalonario(event.target.value);
                    }}
                    placeholder="Introdusca numero del corredor"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Numero de Vaucher
                  </label>
                  <input
                    value={nvaucher}
                    onChange={(event) => {
                      setNvaucher(event.target.value);
                    }}
                    placeholder="Introdusca numero Vaucher"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              
            </div>

           
            <input
              type="submit"
              value="REGISTRAR"
              className="text-white bg-green-600 hover:bg-green-800  focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-10 py-2.5 text-center"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Create;
