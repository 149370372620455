import { useState, useEffect } from "react";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from "react-router-dom";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
//import logo from "../images/logo.png";

const InfraCobertizos = () => {
  //! DB firestore
  const productoreCollection = collection(db, "ganaderos24");

  //! Total apoyo
  const [count23, setCount23] = useState(0);
  const [countNo23, setCountNo23] = useState(0);

  //! spinner
  const [isSaving, setIsSaving] = useState(false);
  const [savingProductId, setSavingProductId] = useState(null);

  const [isSaving4, setIsSaving4] = useState(false);
  const [savingProductId4, setSavingProductId4] = useState(null);

  const [isSaving5, setIsSaving5] = useState(false);
  const [savingProductId5, setSavingProductId5] = useState(null);

  const [isSaving6, setIsSaving6] = useState(false);
  const [savingProductId6, setSavingProductId6] = useState(null);

  //! hooks
  const [productores, setProductores] = useState([]);
  const [productoresFiltrados, setProductoresFiltrados] = useState([]);

  const [terminoBusqueda, setTerminoBusqueda] = useState("");
  const [rangoEdad, setRangoEdad] = useState("");
  const [cobertizos, setCobertizos] = useState("");
  const [numeroVacas, setNumeroVacas] = useState(0);
  const [hectareas, setHectareas] = useState("");
  const [centroPoblado, setCentroPoblado] = useState("");
  const [comunidad, setComunidad] = useState([]);

  const [apoyo23Cobertizo, setApoyo23Cobertizo] = useState(0);

  const [selectedComunidad, setSelectedComunidad] = useState("");

  // ! hooks print
  const [imprimir, setImprimir] = useState("general");

  //! mostrar docs
  const getProductores = () => {
    const unsubscribe = onSnapshot(productoreCollection, (querySnapshot) => {
      setProductores(
        querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );
    });
    return unsubscribe;
  };

  //! useEffect
  useEffect(() => {
    getProductores();
  }, []);

  

  const sumaVacasPorProductor = productoresFiltrados.map((productor) => {
    const resultado =
      Number(productor.ntotalvacunobrownswiss) +
      Number(productor.ntotalvacunoholstein) +
      Number(productor.ntotalvacunootraraza);
    return { productorId: productor.id, sumaVacas: resultado };
  });

  //! filtrar productores según el término de búsqueda
  useEffect(() => {
    let filteredProductores = productores;  
    
    if (terminoBusqueda !== "") {
      filteredProductores = filteredProductores.filter(
        (productor) =>
          productor.dni.toString().includes(terminoBusqueda) ||
          (productor.nombre + " " + productor.apellido.toString())
            .toLowerCase()
            .includes(terminoBusqueda.toLowerCase())
      );
    }

 

   

    if (apoyo23Cobertizo !== null) {
      filteredProductores = filteredProductores.filter((productor) => {
        if (apoyo23Cobertizo === "true") {
          return productor.juevesmaniana === true;
        }
        return true;
      });
    }

    setProductoresFiltrados(filteredProductores);
  }, [
    productores,
    centroPoblado,
    terminoBusqueda,
    rangoEdad,
    cobertizos,
    numeroVacas,
    hectareas,
    selectedComunidad,
    apoyo23Cobertizo,
  ]);

  // Función para actualizar el campo de "apoyo asistencia 2023" en  Firestore
  const handleCheck = async (id, juevesmaniana) => {
    setSavingProductId(id);
    setIsSaving(true);
    // Actualizar arrays de "productores" y "filteredProductores"
    const productorIndex = productores.findIndex((p) => p.id === id);
    if (productorIndex > -1) {
      productores[productorIndex].juevesmaniana =
      juevesmaniana;
      setProductores([...productores]);
    }
    const filteredProductorIndex = productoresFiltrados.findIndex(
      (p) => p.id === id
    );
    if (filteredProductorIndex > -1) {
      productoresFiltrados[filteredProductorIndex].juevesmaniana =
      juevesmaniana;
      setProductoresFiltrados([...productoresFiltrados]);
    }

    // Actualizar campo check en Firestore
    const productorRef = doc(productoreCollection, id);
    await updateDoc(productorRef, { juevesmaniana });

    // Contar el número de productores con check
    const countTres = productoresFiltrados.filter(
      (p) => p.juevesmaniana
    ).length;
    setCount23(countTres);
    // console.log(`Número de productores con asistencia en 2023: ${countTres}`);
    const countNoTres = productoresFiltrados.filter(
      (p) => !p.juevesmaniana
    ).length;
    setCountNo23(countNoTres);

    setSavingProductId(null);
    setIsSaving(false);
  };

  useEffect(() => {
    const countTres = productoresFiltrados.filter(
      (p) => p.juevesmaniana
    ).length;
    setCount23(countTres);
    const countNoTres = productoresFiltrados.filter(
      (p) => !p.juevesmaniana
    ).length;
    setCountNo23(countNoTres);
  }, [productoresFiltrados]);

  const handleCheck4 = async (id, juevestarde) => {
    setSavingProductId4(id);
    setIsSaving4(true);

    const productorIndex4 = productores.findIndex((p) => p.id === id);
    if (productorIndex4 > -1) {
      productores[productorIndex4].juevestarde = juevestarde;
      setProductores([...productores]);
    }
    const filteredProductorIndex4 = productoresFiltrados.findIndex(
      (p) => p.id === id
    );
    if (filteredProductorIndex4 > -1) {
      productoresFiltrados[filteredProductorIndex4].juevestarde =
      juevestarde;
      setProductoresFiltrados([...productoresFiltrados]);
    }

    // Actualizar campo en Firestore
    const productorRef4 = doc(productoreCollection, id);
    await updateDoc(productorRef4, { juevestarde });

    setSavingProductId4(null);
    setIsSaving4(false);
  };

  const handleCheck5 = async (id, viernesmaniana) => {
    setSavingProductId5(id);
    setIsSaving5(true);
    const productorIndex = productores.findIndex((p) => p.id === id);
    if (productorIndex > -1) {
      productores[productorIndex].viernesmaniana = viernesmaniana;
      setProductores([...productores]);
    }
    const filteredProductorIndex = productoresFiltrados.findIndex(
      (p) => p.id === id
    );
    if (filteredProductorIndex > -1) {
      productoresFiltrados[filteredProductorIndex].viernesmaniana =
      viernesmaniana;
      setProductoresFiltrados([...productoresFiltrados]);
    }
    // Actualizar campo en Firestore
    const productorRef = doc(productoreCollection, id);
    await updateDoc(productorRef, { viernesmaniana });
    setSavingProductId5(null);
    setIsSaving5(false);
  };

  const handleCheck6 = async (id, viernestarde) => {
    setSavingProductId6(id);
    setIsSaving6(true);
    const productorIndex = productores.findIndex((p) => p.id === id);
    if (productorIndex > -1) {
      productores[productorIndex].viernestarde = viernestarde;
      setProductores([...productores]);
    }
    const filteredProductorIndex = productoresFiltrados.findIndex(
      (p) => p.id === id
    );
    if (filteredProductorIndex > -1) {
      productoresFiltrados[filteredProductorIndex].viernestarde =
      viernestarde;
      setProductoresFiltrados([...productoresFiltrados]);
    }
    // Actualizar campo en Firestore
    const productorRef = doc(productoreCollection, id);
    await updateDoc(productorRef, { viernestarde });
    setSavingProductId6(null);
    setIsSaving6(false);
  };

  const handlePrint = () => {
    window.print();
  };

  //! vista del componente
  return (
    <div className="min-h-screen pb-5 bg-neutral-50">
      <h2 className="pt-8 mr-5 text-3xl font-black print:text-sm print:uppercase print:pt-1">
        Registro  {""}
        <span className="text-green-500 print:text-black ">de Asistencia</span>
      </h2>
      {/* <div className="hidden print:flex print:flex-row print:justify-end">
        //<img src={logo} alt="logo" className="h-32 absolute top-0 right-7" />
      </div> */}
      <div className="flex justify-end mr-10 print:hidden">
        <Link
          to="/"
          className="bg-green-600 hover:bg-green-700 py-2 px-4 rounded-sm font-bold text-white uppercase hover:shadow-lg"
        >
          Regresar
        </Link>
      </div>
      <div className="overflow-hidden rounded-lg bg-amber-100 shadow-md my-3 mx-10 print:my-2">
        

        <div className="mb-3.5 print:hidden print:mb-0">
          <div className="relative mx-5">
            <input
              value={terminoBusqueda}
              onChange={(e) => setTerminoBusqueda(e.target.value)}
              type="search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border-2 border-amber-400 rounded-lg focus:outline-none focus:border-green-500"
              placeholder="Buscar productor por nombre..."
            />
          </div>
        </div>

        

        <div className="flex flex-row-reverse print:mt-0">
          <div className="w-1/2 flex flex-row justify-end">
            <div className="flex mr-6 print:hidden ">
              <ReactHTMLTableToExcel
                id="table-excel-btn"
                table="table-excel"
                filename="Reporte de apoyo 2023"
                sheet="hoja1"
                buttonText="Exportar a Excel"
                excludeColumns=".excelno"
                className="bg-green-700 px-6 py-2.5 mb-3 rounded-lg font-medium text-white hover:font-bold hover:shadow-2xl"
              />
            </div>

            <div className="flex  mr-6  print:hidden ">
              <button
                onClick={handlePrint}
                className="bg-black px-6 py-2.5 mb-3 rounded-lg font-medium text-white hover:font-bold hover:shadow-2xl"
              >
                Imprimir
              </button>
            </div>
            
          </div>

          {/* <div className="w-1/2 flex flex-row ml-5">
            <div className="flex mr-6">
              <h4 className=" bg-green-50 border border-green-700 px-3 py-1.5 mb-3 rounded-lg shadow-md font-semibold text-lg text-green-900 print:text-xs print:shadow-none">
                Total apoyo 2023: {""}
                <span className="font-black text-lg text-black print:text-xs">
                  {count23}
                </span>
              </h4>
            </div>

            <div className="flex mr-6">
              <h4 className="bg-red-50 border border-red-700 px-3 py-1.5 text-lg mb-3 rounded-lg font-semibold shadow-md text-red-900 print:text-xs print:shadow-none">
                Total no apoyo 2023: {""}
                <span className="font-black text-lg text-black print:text-xs">
                  {countNo23}
                </span>
              </h4>
            </div>
          </div> */}
        </div>

        <table
          id="table-excel"
          className="w-full border-collapse bg-amber-100 text-left text-sm text-gray-500 mt-0 page-break-after:avoid"
        >
          <thead className="bg-amber-400">
            <tr className="text-center font-bold text-indigo-900  border border-amber-600 print:hidden">
              <th
                scope="col"
                className="py-4 border-r border-sky-300 print:border-gray-300 "
                colSpan="6"
              >
                DATOS GENERALES
              </th>
              <th
                scope="col"
                className="py-4 border-r border-sky-300 excelno"
                colSpan="2"
              >
                Lunes
              </th>
              <th scope="col" className=" py-4 font-bold" colSpan="2">
                Martes
              </th>
            </tr>
            <tr className="text-xs text-gray-900 border-b-2 border-amber-600 print:border-gray-400">
              <th className="hidden print:table-cell print:border-gray-300 print:text-xxxs print:pl-0.5 print:py-0">
                N°
              </th>
              <th
                scope="col"
                className="px-4 py-2 border-l border-sky-300 print:border-gray-300 print:text-xxxs print:pl-0.5 print:py-0"
              >
                Nombre y Apellido
              </th>
              <th
                scope="col"
                className="px-2 py-2 print:text-xxxs print:pl-0.5 print:py-0"
              >
                DNI
              </th>
              <th
                scope="col"
                className="px-2 py-2 print:text-xxxs print:pl-0.5 print:py-0"
              >
                Edad
              </th>           
              <th
                scope="col"
                className="px-2 py-2 print:text-xxxs print:pl-0.5 print:py-0"
              >
                Procedencia
              </th>    
              <th
                scope="col"
                className="px-2 py-2 print:text-xxxs print:pl-0.5 print:py-0"
              >
                Categoria
              </th>              
              
              
              <th
                scope="col"
                className="py-3 px-2 font-medium border-r border-sky-300 print:border-gray-300 print:text-xxxs  print:py-0 excelno"
              >
                Nª Vaucher
              </th>
              
              <th
                scope="col"
                className={`px-2 py-2 text-center print:text-xxxs print:p-0.5  ${
                  imprimir === "23" || imprimir === "general"
                    ? ""
                    : "print:hidden"
                }
                `}
              >
                Mañana
              </th>
              <th
                scope="col"
                className={`px-2 py-2 text-center font-medium border-r border-sky-300 print:border-gray-300  print:text-xxxs print:p-0.5 ${
                  imprimir === "24" || imprimir === "general"
                    ? ""
                    : "print:hidden"
                }
                `}
              >
                Tarde
              </th>
              
              <th
                scope="col"
                className={`px-2 py-2 text-center print:text-xxxs print:p-0.5 ${
                  imprimir === "25" || imprimir === "general"
                    ? ""
                    : "print:hidden"
                }
                `}
              >
                Mañana
              </th>

              <th
                scope="col"
                className={`px-2  py-2  border-r border-sky-300 text-center print:border-gray-300 print:text-xxxs print:p-0.5 ${
                  imprimir === "26" || imprimir === "general"
                    ? ""
                    : "print:hidden"
                }
                `}
              >
                Tarde
              </th>
            </tr>
            <tr>
              
            </tr>
          </thead>

          <tbody className="divide-y divide-sky-200 border-t border-sky-200 print:border-gray-300 print:divide-none">
            {productores.length !== 0 ? (
              productoresFiltrados.map((productor, index) => (
                <tr key={productor.id} className="hover:bg-sky-100">
                  <td className="hidden print:text-center print:table-cell print:text-xxxs print:p-0">
                    {index + 1}
                  </td>

                  <td className="hidden print:table-cell print:text-xxxs print:p-0">{`${productor.nombre} ${productor.apellido}`}</td>
                  <td className="flex gap-2 px-4 py-3 font-normal text-gray-900  print:hidden">
                    <div className="text-sm print:text-xxs print:hidden">
                      {productor.nombre.length
                        ? productor.nombre
                        : "Nombre no disponible"}
                      <div className="text-gray-400 print:text-xxs">
                        {productor.apellido.length ? productor.apellido : "..."}
                      </div>
                    </div>
                  </td>

                  <td className="px-2 py-3 text-xs print:text-xxs print:p-0">
                    {productor.dni}
                  </td>

                  <td className="px-2 py-3 text-xs print:text-xxs print:p-0 print:text-center ">
                    {productor.edad}
                    </td>  
                    <td className="px-2 py-3 text-xs print:text-xxs print:p-0 print:text-center ">
                    {productor.procedencia}
                    </td>              

                    <td className="px-2 py-3 text-xs print:text-xxs print:p-0 print:text-center ">
                    {productor.modalidad}
                    </td> 

                  <td className=" px-2 py-1 border-r border-sky-200  print:border-gray-300 print:text-xxxs print:p-0 excelno">
                  {productor.nvaucher}
                  </td>
                  

                  <td
                    className={`text-center py-3 print:p-0 ${
                      imprimir === "23" || imprimir === "general"
                        ? ""
                        : "print:hidden"
                    }

`}
                  >
                    <input
                      type="checkbox"
                      className={`rounded w-4 h-4 ${
                        productor.asistenciatresTerneraje ||
                        productor.asistenciatreseniles
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      checked={productor.juevesmaniana || false}
                      onChange={(e) => {
                        handleCheck(productor.id, e.target.checked);
                      }}
                      disabled={
                        productor.asistenciatresTerneraje ||
                        productor.asistenciatreseniles
                      }
                    />
                    {savingProductId === productor.id && isSaving && (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin h-7 w-7 text-sky-500 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm10-5.291a7.962 7.962 0 01-2 5.291l3 2.647A8.01 8.01 0 0120 12h-4zm-6-7.938A7.962 7.962 0 0112 4v4c1.66 0 3.182.646 4.334 1.698l-3 2.646z"
                          ></path>
                        </svg>
                        <span className="text-sky-500 font-bold">
                          Guardando...
                        </span>
                      </div>
                    )}
                  </td>

                  <td
                    className={`text-center font-medium border-r border-sky-300 print:border-gray-300 py-3 print:p-0 ${
                      imprimir === "24" || imprimir === "general"
                        ? ""
                        : "print:hidden"
                    }
                              `}
                  >
                    <input
                      type="checkbox"
                      className={`rounded w-4 h-4 ${
                        productor.apoyo2024Terneraje ||
                        productor.apoyo2024eniles
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      checked={productor.juevestarde|| false}
                      onChange={(e) =>
                        handleCheck4(productor.id, e.target.checked)
                      }
                      disabled={
                        productor.apoyo2024Terneraje ||
                        productor.apoyo2024eniles
                      }
                    />
                    {savingProductId4 === productor.id && isSaving4 && (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin h-7 w-7 text-sky-500 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm10-5.291a7.962 7.962 0 01-2 5.291l3 2.647A8.01 8.01 0 0120 12h-4zm-6-7.938A7.962 7.962 0 0112 4v4c1.66 0 3.182.646 4.334 1.698l-3 2.646z"
                          ></path>
                        </svg>
                        <span className="text-sky-500 font-bold">
                          Guardando...
                        </span>
                      </div>
                    )}
                  </td>
                  <td
                    className={`text-center py-3 print:p-0 ${
                      imprimir === "25" || imprimir === "general"
                        ? ""
                        : "print:hidden"
                    }
                            `}
                  >
                    <input
                      type="checkbox"
                      className={`rounded w-4 h-4 ${
                        productor.apoyo2025Terneraje ||
                        productor.apoyo2025eniles
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      checked={productor.viernesmaniana || false}
                      onChange={(e) =>
                        handleCheck5(productor.id, e.target.checked)
                      }
                      disabled={
                        productor.apoyo2025Terneraje ||
                        productor.apoyo2025eniles
                      }
                    />
                    {savingProductId5 === productor.id && isSaving5 && (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin h-7 w-7 text-sky-500 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm10-5.291a7.962 7.962 0 01-2 5.291l3 2.647A8.01 8.01 0 0120 12h-4zm-6-7.938A7.962 7.962 0 0112 4v4c1.66 0 3.182.646 4.334 1.698l-3 2.646z"
                          ></path>
                        </svg>
                        <span className="text-sky-500 font-bold">
                          Guardando...
                        </span>
                      </div>
                    )}
                  </td>
                  <td
                    className={`py-3 text-center print:p-0 ${
                      imprimir === "26" || imprimir === "general"
                        ? ""
                        : "print:hidden"
                    }
                  `}
                  >
                    <input
                      type="checkbox"
                      className={`rounded w-4 h-4 ${
                        productor.apoyo2026Terneraje ||
                        productor.apoyo2026eniles
                          ? "cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      checked={productor.viernestarde || false}
                      onChange={(e) =>
                        handleCheck6(productor.id, e.target.checked)
                      }
                      disabled={
                        productor.apoyo2026Terneraje ||
                        productor.apoyo2026eniles
                      }
                    />
                    {savingProductId6 === productor.id && isSaving6 && (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin h-7 w-7 text-sky-500 mr-3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647zm10-5.291a7.962 7.962 0 01-2 5.291l3 2.647A8.01 8.01 0 0120 12h-4zm-6-7.938A7.962 7.962 0 0112 4v4c1.66 0 3.182.646 4.334 1.698l-3 2.646z"
                          ></path>
                        </svg>
                        <span className="text-sky-500 font-bold">
                          Guardando...
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="11"
                  className="grid mx-[120%] my-8 w-full place-items-center"
                  role="status"
                >
                  <svg
                    aria-hidden="true"
                    className="inline w-14 h-14 mr-2 text-gray-200 animate-spin fill-red-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="page-number"></div>
      </div>
    </div>
  );
};

export default InfraCobertizos;
